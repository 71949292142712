@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	scroll-behavior: smooth;
}

@layer components {
	.smooth-hover {
		@apply transform transition-all delay-150 duration-300 ease-in;
	}

	.icon-xs {
		@apply h-4 w-4;
	}

	.icon-sm {
		@apply h-6 w-6;
	}

	.icon-md {
		@apply h-8 w-8;
	}

	.icon-lg {
		@apply h-12 w-12;
	}

	.icon-xl {
		@apply h-16 w-16;
	}

	.btn {
		@apply smooth-hover flex appearance-none items-center justify-center rounded-sm border text-sm font-medium text-white outline-none ring-0 hover:opacity-80 disabled:opacity-70;
	}

	.btn-default {
		@apply border-transparent bg-transparent;
	}

	.btn-secondary {
		@apply border-slate-700 bg-slate-700;
	}

	.btn-primary {
		@apply border-primary bg-primary;
	}

	.btn-success {
		@apply border-success bg-success;
	}

	.btn-warning {
		@apply border-warning bg-warning;
	}

	.btn-danger {
		@apply border-danger bg-danger;
	}

	.btn-xs {
		@apply py-2 px-2;
	}

	.btn-sm {
		@apply py-2 px-2 md:px-4;
	}

	.btn-md {
		@apply py-3 px-4 md:px-6 md:text-sm;
	}

	.btn-lg {
		@apply py-4 px-8 text-base;
	}

	.form-control {
		@apply block h-12 w-full appearance-none rounded-sm border p-5 text-sm font-normal text-dark-blue outline-none ring-0 placeholder:text-slate-500 focus:ring-0 disabled:opacity-80;
	}

	.card-shadow {
		box-shadow: 0 2px 14px 5px rgb(20 20 20 / 5%);
	}

	.contact-banner {
		background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
			url('./assets//images/building.jpg') no-repeat;
		background-position: center;
		background-size: cover;
		background-attachment: fixed;
	}
	  .container-about {
		background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		  url("../public/service/boutique.jpeg") no-repeat;
		background-position: center;
		background-size: cover;
		background-attachment: fixed;
	  }
}
a:hover {
	color: unset;
	text-decoration: none;
  }

  #booking-widget .row a,
  #booking-widget .show-cp,
  #booking-widget .vue-show-details,
  #booking-widget .vue-show-tc {
	color: rgb(29 195 178);
  }

  .mx-calendar-content .cell.active,
  #booking-widget .btn-orange-solid,
  #booking-widget .btn-solid {
	
	width: fit-content;
	background-color: rgb(29 195 178) !important;
	border-radius: 0.375rem;
  }
  #booking-widget{
	margin-left: 0px;
	margin-top: 30px;
  }

  .mx-calendar-content .cell.active:hover,
  #booking-widget .btn-orange-solid:hover,
  #booking-widget .btn-solid:hover {
	background: white !important;
	border-inline: 0.5px solid rgb(29 195 178) !important;
	opacity: 1;
	color: rgb(29 195 178);
  }

  #vue-toggle_residency .vue-btn.active {
	background: rgb(29 195 178 / var(--tw-bg-opacity)) !important;
	border-color: rgb(29 195 178 / var(--tw-bg-opacity)) !important;
  }

  #vue-toggle_residency .vue-btn {
	color: rgb(29 195 178 / var(--tw-bg-opacity));
	background: none !important;
	border-color: rgb(29 195 178 / var(--tw-bg-opacity)) !important;
  }
  #booking-widget .ui-pnotify.ui-pnotify-inline[data-v-3aaf748d] {
	background-color: #a27e4f !important;
	border-radius: 4px;
  }

  #booking-widget .reserveport-form-wrap h3,
  #booking-widget h1.room-title,
  #booking-widget .vue-side_top_card h3,
  .vue-side_bottom_card {
	font-family: Montserrat;
  }
  .reserveport-form-wrap{
	margin-top: 10px;
  }

  .inner {
	box-shadow: 0px 6px 6px 0 rgb(0 0 0 / 7%);
  }

  .room-title-details .pull-right {
	text-align: end;
  }

  .mobile_booking_wrapper {
	left: 0;
  }

  #image-lightbox {
	display: flex;
	justify-content: center;
  }

  #image-lightbox .row {
	margin-left: 0 !important;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
	margin-bottom: 0;
  }

  .py-5 {
	padding-top: 1.25rem !important;
	padding-bottom: 1.25rem !important;
  }

  .py-3 {
	padding-top: 0.25rem !important;
	padding-bottom: 0.25rem !important;
  }

  /* .my-3 {
	margin-top: unset !important;
	margin-bottom: unset !important;
  } */

  .btn {
	display: flex;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 500;
  }

  /* .btn-success {
	background-color: rgb(29 195 178 / var(--tw-bg-opacity));
	border: none;
  } */

  @media only screen and (max-width: 600px) {
	#image-lightbox .modal-dialog {
	  position: relative;
	  transform: translate(0%, 0%) !important;
	  left: 0;
	  max-width: none;
	}
	#image-lightbox .row {
	  margin-right: 0;
	}
	#image-lightbox .vue-lightbox img {
	  width: 100%;
	}
  }


